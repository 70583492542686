/* istanbul ignore file */
import at from './types';

export const fetchCourseContent = (contentPagesData, testData, surveyData, quizData, standardizedPageData) => ({
  type: at.FETCH_COURSE_CONTENT_REQUEST,
  contentPagesData,
  testData,
  surveyData,
  quizData,
  standardizedPageData,
});

export const createSteps = (steps) => ({
  type: at.CREATE_STEPS,
  steps,
});

export const selectStep = (index) => ({
  type: at.SELECT_STEP,
  index,
});

export const selectAnswer = (answerId, questionIndex, currentStepIndex) => ({
  type: at.SELECT_ANSWER,
  payload: { answerId, questionIndex, currentStepIndex },
});

export const selectQuestion = (questionIndex, currentStepIndex) => ({
  type: at.SELECT_QUESTION,
  payload: { questionIndex, currentStepIndex },
});

export const updateShortAnswerResponse = (questionIndex, currentStepIndex, responseText) => ({
  type: at.UPDATE_SHORT_ANSWER_RESPONSE,
  payload: { questionIndex, currentStepIndex, responseText },
});

export const updateTextMatcherResponse = (questionIndex, currentStepIndex, responseText) => ({
  type: at.UPDATE_TEXT_MATCHER_RESPONSE,
  payload: { questionIndex, currentStepIndex, responseText },
});

export const updateTestAnswersState = (data) => ({
  type: at.UPDATE_TEST_ANSWERS_STATE,
  data,
});

export const clearUpdateTestAnswersStateStatus = () => ({
  type: at.CLEAR_UPDATE_TEST_ANSWERS_STATE_STATUS,
});

export const saveQuizAnswers = (quizId, data, selectedStepIndex) => ({
  type: at.SAVE_QUIZ_ANSWERS_REQUEST,
  payload: { quizId, data, selectedStepIndex },
});

export const clearSaveQuizAnswersStatus = () => ({
  type: at.CLEAR_SAVE_QUIZ_ANSWERS_STATUS,
});

export const clearQuizSelectedAnswers = (currentStepIndex) => ({
  type: at.CLEAR_QUIZ_SELECTED_ANSWERS,
  currentStepIndex,
});

export const saveSurveyAnswers = (surveyId, body, selectedStepIndex) => ({
  type: at.SAVE_SURVEY_ANSWERS_REQUEST,
  payload: { surveyId, body, selectedStepIndex },
});

export const clearSaveSurveyAnswersStatus = () => ({
  type: at.CLEAR_SAVE_SURVEY_ANSWERS_STATUS,
});

export const createStandardizedPageLaunchUrl = (studentId, standardizedPageId) => ({
  type: at.CREATE_STANDARDIZED_PAGE_LAUNCH_URL_REQUEST,
  payload: { studentId, standardizedPageId },
});

export const clearCreateStandardizedPageLaunchUrl = () => ({
  type: at.CLEAR_STANDARDIZED_PAGE_LAUNCH_URL_STATUS,
});

export const fetchStandardizedPageLaunchUrl = (studentId, standardizedPageId) => ({
  type: at.FETCH_STANDARDIZED_PAGE_LAUNCH_URL_REQUEST,
  payload: { studentId, standardizedPageId },
});

export const clearFetchStandardizedPageLaunchUrl = () => ({
  type: at.CLEAR_FETCH_STANDARDIZED_PAGE_LAUNCH_URL_STATUS,
});

export const updateStandardizedPageVersionState = (data) => {
  return { type: at.UPDATE_STANDARDIZED_PAGE_VERSION_STATE, data };
};
