/* istanbul ignore file */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import WebFont from 'webfontloader';
import { store } from './store/configureStore';
import * as serviceWorker from './serviceWorker';
import { courseFlowTheme } from './theme';
import { InitGATracker, GAPagesTracker, InitGATrackerMetrics } from './components/GATracker';
import { InitGTMTracker } from './helpers/gtmHelper';
import { InitAdRollTracker, AdRollTrack } from './helpers/adRollHelper';
import 'emerald-ui/lib/styles-tm.css';
import settings from './config/settings';

import App from './containers/App';

InitGATracker();
InitGATrackerMetrics();
InitGTMTracker();
InitAdRollTracker();
AdRollTrack('pageView');

const TrackedApp = GAPagesTracker(App);

WebFont.load({
  google: {
    families: ['Merriweather'],
  },
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <ThemeProvider theme={courseFlowTheme}>
        <TrackedApp />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
