/* istanbul ignore file */
export default {
  FETCH_COURSE_CONTENT_REQUEST: 'content/FETCH_COURSE_CONTENT_REQUEST',
  FETCH_COURSE_CONTENT_SUCCESS: 'content/FETCH_COURSE_CONTENT_SUCCESS',
  FETCH_COURSE_CONTENT_FAILURE: 'content/FETCH_COURSE_CONTENT_FAILURE',

  CREATE_STEPS: 'content/CREATE_STEPS',
  SELECT_STEP: 'content/SELECT_STEP',

  SELECT_QUESTION: 'content/SELECT_QUESTION',
  SELECT_ANSWER: 'content/SELECT_ANSWER',
  UPDATE_SHORT_ANSWER_RESPONSE: 'content/UPDATE_SHORT_ANSWER_RESPONSE',
  UPDATE_TEXT_MATCHER_RESPONSE: 'content/UPDATE_TEXT_MATCHER_RESPONSE',

  UPDATE_TEST_ANSWERS_STATE: 'content/UPDATE_TEST_ANSWERS_STATE',
  CLEAR_UPDATE_TEST_ANSWERS_STATE_STATUS: 'content/CLEAR_UPDATE_TEST_ANSWERS_STATE_STATUS',

  SAVE_QUIZ_ANSWERS_REQUEST: 'content/SAVE_QUIZ_ANSWERS_REQUEST',
  SAVE_QUIZ_ANSWERS_SUCCESS: 'content/SAVE_QUIZ_ANSWERS_SUCCESS',
  SAVE_QUIZ_ANSWERS_FAILURE: 'content/SAVE_QUIZ_ANSWERS_FAILURE',
  CLEAR_QUIZ_SELECTED_ANSWERS: 'content/CLEAR_QUIZ_SELECTED_ANSWERS',
  CLEAR_SAVE_QUIZ_ANSWERS_STATUS: 'content/CLEAR_SAVE_QUIZ_ANSWERS_STATUS',

  SAVE_SURVEY_ANSWERS_REQUEST: 'content/SAVE_SURVEY_ANSWERS_REQUEST',
  SAVE_SURVEY_ANSWERS_SUCCESS: 'content/SAVE_SURVEY_ANSWERS_SUCCESS',
  SAVE_SURVEY_ANSWERS_FAILURE: 'content/SAVE_SURVEY_ANSWERS_FAILURE',
  CLEAR_SAVE_SURVEY_ANSWERS_STATUS: 'content/CLEAR_SAVE_SURVEY_ANSWERS_STATUS',

  REQUEST_FAILURE: 'course/REQUEST_FAILURE',

  CREATE_STANDARDIZED_PAGE_LAUNCH_URL_REQUEST: 'content/CREATE_STANDARDIZED_PAGE_LAUNCH_URL_REQUEST',
  CREATE_STANDARDIZED_PAGE_LAUNCH_URL_SUCCESS: 'content/CREATE_STANDARDIZED_PAGE_LAUNCH_URL_SUCCESS',
  CREATE_STANDARDIZED_PAGE_LAUNCH_URL_FAILURE: 'content/CREATE_STANDARDIZED_PAGE_LAUNCH_URL_FAILURE',
  CLEAR_STANDARDIZED_PAGE_LAUNCH_URL_STATUS: 'content/CLEAR_STANDARDIZED_PAGE_LAUNCH_URL_STATUS',

  FETCH_STANDARDIZED_PAGE_LAUNCH_URL_REQUEST: 'content/FETCH_STANDARDIZED_PAGE_LAUNCH_URL_REQUEST',
  FETCH_STANDARDIZED_PAGE_LAUNCH_URL_SUCCESS: 'content/FETCH_STANDARDIZED_PAGE_LAUNCH_URL_SUCCESS',
  FETCH_STANDARDIZED_PAGE_LAUNCH_URL_FAILURE: 'content/FETCH_STANDARDIZED_PAGE_LAUNCH_URL_FAILURE',
  CLEAR_FETCH_STANDARDIZED_PAGE_LAUNCH_URL_STATUS: 'content/CLEAR_FETCH_STANDARDIZED_PAGE_LAUNCH_URL_STATUS',

  UPDATE_STANDARDIZED_PAGE_VERSION_STATE: 'content/UPDATE_STANDARDIZED_PAGE_VERSION_STATE',
};
